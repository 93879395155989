import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'

const Footer = () => {
    return(
        <footer>
            <a href='#' className='footer__logo'>Che-Ming (Jimmy) Chung</a>
            <ul className="permalinks">
                <li><a href="/#">Home</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
                {/*<a href="https://facebook.com" target='_blank' rel="noreferrer"><FaFacebookF/></a>    */}
                <a href="https://www.instagram.com/imi.___._/" target='_blank' rel="noreferrer"><FiInstagram/></a>    
                <a href="https://twitter.com/imi_chung" target='_blank' rel="noreferrer"><IoLogoTwitter/></a>    
            </div>  

            {/*
            <div className="footer__copyright">
                <small>
                    &copy; EGATOR Tutorials. All rights reserved.
                </small>
            </div>
            
            */}

        </footer>
    )
}

export default Footer