import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'
import {SiHtml5, SiTypescript, SiCss3, SiReact, SiJavascript, SiPython, SiDjango, SiPostgresql, SiMongodb, SiFirebase, SiPostman} from 'react-icons/si'
const Experience = () => {
    return (
        <section id='experience'>
            <h5>What Skills I Have</h5>
            <h2>My Experience</h2>

            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Frontend Development</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <SiJavascript className='experience__details-icon'/>
                            <div>
                                <h4>JavaScript</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiReact className='experience__details-icon'/>
                            <div>
                                <h4>React</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiHtml5 className='experience__details-icon'/>
                            <div>
                                <h4>HTML</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiCss3 className='experience__details-icon'/>
                            <div>
                                <h4>CSS</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiTypescript className='experience__details-icon'/>
                            <div>
                                <h4>TypeScript</h4>
                                <small className='text-light'>Familiar</small>
                            </div>
                        </article>
                    </div>
                </div>
                {/*BACKEND*/}
                <div className="experience__backend">
                    <h3>Backend Development</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <SiPython className='experience__details-icon'/>
                            <div>
                                <h4>Python</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiDjango className='experience__details-icon'/>
                            <div>
                                <h4>Django</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiPostgresql className='experience__details-icon'/>
                            <div>
                                <h4>PosgreSQL</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiPostman className='experience__details-icon'/>
                            <div>
                                <h4>Postman</h4>
                                <small className='text-light'>Experienced</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiMongodb className='experience__details-icon'/>
                            <div>
                                <h4>MongoDB</h4>
                                <small className='text-light'>Familiar</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <SiFirebase className='experience__details-icon'/>
                            <div>
                                <h4>FireBase</h4>
                                <small className='text-light'>Fimiliar</small>
                            </div>
                        </article>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default Experience