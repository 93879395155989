import React from "react"
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/portfolio5.png'


const data = [
    {
        id: 1,
        image: IMG1,
        title: 'U School Schedule Management System for the University of Alberta',
        github: null,
        demo: 'https://youtu.be/vh7YIemEY_4'
    },
    {
        id: 2,
        image: IMG2,
        title: 'Distributed Social Media Network',
        github: 'https://github.com/CMPUT404GroupProject/CMPUT404Project',
        demo: 'https://drive.google.com/file/d/1usyFW9xW-24nZnJ8xzVHYVAfI3WwWr4T/view?usp=sharing'
    },
    {
        id: 3,
        image: IMG3,
        title: 'Book Tracker Android Application',
        github: 'https://github.com/Mimi82n2/BookTracker',
        demo: 'https://drive.google.com/file/d/1WLkqPstCv2E2NqAxfEgM2oDXhx4vef7P/view?usp=sharing'
    },
    {
        id: 4,
        image: IMG4,
        title: 'HACKATHON Project: Restaurant Review Web Application',
        github: 'https://github.com/Meilin-Lyu/Suggestions_for_lunch',
        demo: null
    },
    {
        id: 5,
        image: IMG5,
        title: 'Landing Page: HTML and CSS Practice',
        github: 'https://github.com/Mimi82n2/TruffleLand',
        demo: 'https://mimi82n2.github.io/TruffleLand/'

    }
]
const Portfolio = () =>{
    return(
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
                {
                    data.map(({id, image, title, github, demo}) => {
                        return (
                            <article key={id} className="portfolio__item">
                                <div className="portfolio__item-image">
                                    <img src={image} alt={title}/>
                                </div>
                                <h3>{title}</h3>
                                <div className="portfolio__item-cta">
                                    {demo !== null
                                        ? <a href={demo} className='btn btn-primary' target='_blank' rel='noreferrer'>Demo</a>
                                        : <a href={demo} className='btn btn-primary' target='_blank' rel='noreferrer' style={{visibility:'hidden'}}>Demo</a>
                                    }
                                    {github !== null 
                                        ? <a href={github} className='btn' target='_blank' rel='noreferrer'>Github</a>
                                        : <a href={github} className='btn' target='_blank' rel='noreferrer' style={{visibility:'hidden'}}>Github</a>
                                    }
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </section>
    )
}
export default Portfolio