import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import CTA from './CTA'

const About = () => {
    return (
        <section id='about'>
            <div className="container about__container">
                <div className='about__me'>
                    <div className='about__me-image'>
                        <img src={ME} alt="About Image"/>
                    </div>
                </div>

                <div className='about__content'>
                    {/* 
                    <div className='about__cards'>
                        <article className='about__card'>
                            <FaAward className='about__icon'/>
                            <h5>Experience</h5>
                            <small>3+ Years Working</small>
                        </article>
                        <article className='about__card'>
                            <FiUsers className='about__icon'/>
                            <h5>Clients</h5>
                            <small>200+ Worldwide</small>
                        </article>
                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon'/>
                            <h5>Projects</h5>
                            <small>80+ Completed</small>
                        </article>
                    </div>
                    */}
                    <h5 className='hi'>Hi, my name is</h5>
                    <h1 className='name'>Jimmy Chung</h1>
                    
                    <p>
                        I'm a software engineer who specializes in building exceptional digital experiences. Currently, I'm building a website for <a href='https://www.ualberta.ca/chancellor-and-senate/u-school/index.html' target='_blank' rel="noreferrer">U School</a> at the University of Alberta as a Full-Stack Developer.
                    </p>
                    <CTA/>
                    
                </div>
            </div>
        </section>
    )
}

export default About