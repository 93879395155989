import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from "./HeaderSocials"
import About from './About'

const Header = () => {
    return(
        <header>
            <div className="container header__container">
                {/*
                <h5>Hi, my name is</h5>
                <h1>Jimmy Chung</h1>
                <h5 className="text-light">Web Developer</h5>
                <CTA/>
                */}
                <HeaderSocial/>
                <About/>
                {/* 
                <div className="me">
                    <img src={ME} alt="me"/>
                </div>
                */}
                <a href="#contact" className="scroll__down">Scroll Down</a>                
            </div>
        </header>
    )
}

export default Header